export var PaymentMethodType;
(function (PaymentMethodType) {
    PaymentMethodType["STRIPE"] = "STRIPE";
    PaymentMethodType["BACS"] = "BACS";
    PaymentMethodType["FINANCE_PAYOUT"] = "FINANCE_PAYOUT";
    PaymentMethodType["UNKNOWN"] = "UNKNOWN";
    PaymentMethodType["OTHER"] = "OTHER";
    PaymentMethodType["CASH"] = "CASH";
    PaymentMethodType["CARD"] = "CARD";
    PaymentMethodType["CHEQUE"] = "CHEQUE";
})(PaymentMethodType || (PaymentMethodType = {}));
